import { Box, Link, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { MenuEntry, MenuLink } from "./menu"
import { Metadata } from "./metadata"

// https://pro.chakra-ui.com/components/marketing/footers
export default () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      menu: file(relativePath: { eq: "menus/footer.yml" }) {
        childMenusYaml {
          entries {
            icon
            title
            url
          }
        }
      }
    }
  `)
  const currentSlug = useLocation().pathname

  return (
    <Box as="footer" gridArea="footer" bg="primary.600" pt={5} pb={5}>
      <VStack spacing={5} textColor="white" fontSize="sm">
        <Metadata />
        <Wrap spacing={5} justify="center" textColor="black">
          {data.menu.childMenusYaml.entries.map((entry: MenuEntry) => (
            <WrapItem key={entry.url}>
              <MenuLink entry={entry} currentSlug={currentSlug} />
            </WrapItem>
          ))}
        </Wrap>
        <VStack>
          <Text>&copy; {new Date().getFullYear()} Wolfs HLS</Text>
          <Text>
            Realisiert von{" "}
            <Link href="https://www.mirko-lenz.de">Mirko Lenz</Link>
          </Text>
        </VStack>
      </VStack>
    </Box>
  )
}
