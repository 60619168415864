import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Img,
  Link,
  Spacer,
} from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import React from "react"
import { mainmenu } from "./menu"

interface Props {
  siteTitle: string
  onShowSidebar: () => void
}
// const { toggleColorMode } = useColorMode()

function MobileHeader({ onShowSidebar, siteTitle }) {
  return (
    <Box display={{ base: "flex", md: "none" }}>
      <Center flex="1">
        <IconButton
          icon={<FontAwesomeIcon icon={["fas", "bars"]} />}
          onClick={onShowSidebar}
          aria-label="Show sidebar"
        />
      </Center>
      <Center flex="10">
        <Link as={GatsbyLink} to="/">
          <Img height={75} m={2} src="/assets/logo.svg" alt={siteTitle} />
        </Link>
      </Center>
      <Box flex="1" />
    </Box>
  )
}

function DesktopHeader({ siteTitle }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Flex display={{ base: "none", md: "flex" }}>
      <Box>
        <Link as={GatsbyLink} to="/">
          <Img height={75} m={2} src="/assets/logo.svg" alt={siteTitle} />
        </Link>
      </Box>
      <Spacer />
      <Center display={{ md: "none", lg: "flex" }}>
        <Link as={GatsbyLink} to="/" _hover={{}}>
          <Heading as="span" size="md" color="white">
            {site.siteMetadata.title}
          </Heading>
        </Link>
      </Center>
      <Spacer />
      <HStack spacing={5}>{mainmenu()}</HStack>
    </Flex>
  )
}

const Header: React.FC<Props> = ({ onShowSidebar, siteTitle }) => {
  return (
    <Box gridArea="header" bg="primary.600">
      <Container maxW="container.xl" as="header">
        <MobileHeader onShowSidebar={onShowSidebar} siteTitle={siteTitle} />
        <DesktopHeader siteTitle={siteTitle} />
      </Container>
    </Box>
  )
}

export default Header
