import { Button, Center, Grid, Link, Stack, Text } from "@chakra-ui/react"
import {
  faClock,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import React from "react"

export function Metadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phone
            email
            openingHours
            address
          }
        }
      }
    `
  )

  const meta = site.siteMetadata

  return (
    <Stack spacing={5}>
      <Center>
        <Link as={GatsbyLink} to="/leistungen/baderwelten/" _hover={{}}>
          <Button leftIcon={<FontAwesomeIcon icon={faClock} />} color="black">
            Beratung Bäderwelten auf Anfrage
          </Button>
        </Link>
      </Center>
      <Center>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          gap={{ base: 2, md: 10 }}
        >
          <MetaColumn
            entries={[{ text: meta.address, icon: faMapMarkerAlt }]}
          />
          <MetaColumn entries={[{ text: meta.openingHours, icon: faClock }]} />
          <MetaColumn
            entries={[
              { text: meta.phone, link: `tel:${meta.phone}`, icon: faPhone },
              {
                text: meta.email,
                link: `mailto:${meta.email}`,
                icon: faEnvelope,
              },
            ]}
          />
        </Grid>
      </Center>
    </Stack>
  )
}

function MetaColumn({ entries }) {
  return (
    <Stack spacing={{ base: 2, md: 0 }}>
      {entries.map((entry, i) =>
        entry.element ? entry.element : <MetaItem entry={entry} key={i} />
      )}
    </Stack>
  )
}

function MetaItem({ entry }) {
  return (
    <Stack spacing={2} direction="row">
      {entry.icon && (
        <Text>
          <FontAwesomeIcon icon={entry.icon} />
        </Text>
      )}
      <Stack spacing={0}>
        {entry.link ? (
          <Link href={entry.link}>
            <MetaItemText text={entry.text} />
          </Link>
        ) : (
          <MetaItemText text={entry.text} />
        )}
      </Stack>
    </Stack>
  )
}

function MetaItemText({ text }) {
  return text.split("\n").map((line, i) => <Text key={i}>{line}</Text>)
}
