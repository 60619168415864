/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { config } from "@fortawesome/fontawesome-svg-core"
import { GatsbyBrowser } from "gatsby"
import { wrapPageElement as wrap } from "./src/root-wrapper"

// https://github.com/jzabala/gatsby-plugin-fontawesome-css/blob/master/gatsby-browser.js
export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = wrap
