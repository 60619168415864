import { Button, Link } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import React from "react"

export interface MenuEntry {
  icon: any
  title: string
  url: string
}

export function MenuLink({
  entry,
  currentSlug,
  onClick = () => {},
  size = "md",
  hideIcon = false,
}) {
  const button = (
    <Button
      isActive={currentSlug === entry.url}
      justifyContent="left"
      leftIcon={
        hideIcon ? null : <FontAwesomeIcon icon={["fas", entry.icon]} />
      }
      w="100%"
      size={size}
    >
      {entry.title}
    </Button>
  )

  if (entry.url.startsWith("/")) {
    return (
      <Link as={GatsbyLink} to={entry.url} _hover={{}} onClick={onClick}>
        {button}
      </Link>
    )
  }

  return (
    <Link href={entry.url} _hover={{}} onClick={onClick}>
      {button}
    </Link>
  )
}

export function mainmenu(onClick = () => {}) {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      menu: file(relativePath: { eq: "menus/main.yml" }) {
        childMenusYaml {
          entries {
            icon
            title
            url
          }
        }
      }
    }
  `)
  const currentSlug = useLocation().pathname

  return data.menu.childMenusYaml.entries.map((entry: MenuEntry) => (
    <MenuLink
      key={entry.url}
      entry={entry}
      currentSlug={currentSlug}
      onClick={onClick}
    />
  ))
}
