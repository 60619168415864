import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Link,
  Stack,
} from "@chakra-ui/react"
import { faTools } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import { deburr, sortBy } from "lodash"
import React from "react"
import { mainmenu, MenuEntry, MenuLink } from "./menu"

interface MobileProps {
  onClose: () => void
  isOpen: boolean
}

interface ServiceArea {
  title: string
  icon: any
  fields: {
    slug
  }
  parent: {
    name: string
  }
}

interface Service {
  title: string
  serviceArea: string
  fields: {
    slug: string
  }
}

const SidebarArea: React.FC<{
  area: ServiceArea
  services: Service[]
  currentSlug: string
  onClick
}> = ({ area, services, currentSlug, onClick = () => {} }) => {
  if (services.length == 1) {
    const service = services[0]

    const entry: MenuEntry = {
      icon: area.icon,
      title: service.title,
      url: service.fields.slug,
    }

    return (
      <MenuLink
        key={entry.url}
        entry={entry}
        currentSlug={currentSlug}
        onClick={onClick}
      />
    )
  }

  return (
    <Stack pt={2} pb={2}>
      <Heading as="span" size="sm">
        <FontAwesomeIcon icon={["fas", area.icon]} />
        &nbsp;
        <Link
          as={GatsbyLink}
          to={area.fields.slug}
          textDecoration={currentSlug === area.fields.slug ? "underline" : null}
        >
          {area.title}
        </Link>
      </Heading>
      <Stack>
        {services.map(service => {
          const entry: MenuEntry = {
            icon: area.icon,
            title: service.title,
            url: service.fields.slug,
          }

          return (
            <MenuLink
              key={entry.url}
              entry={entry}
              currentSlug={currentSlug}
              onClick={onClick}
              size="sm"
              hideIcon
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

const SidebarContent = ({ onClick = () => {} }) => {
  const data = useStaticQuery(graphql`
    query SidebarQuery {
      serviceAreas: allLeistungsbereicheYaml {
        nodes {
          title
          icon
          fields {
            slug
          }
          parent {
            ... on File {
              name
            }
          }
        }
      }
      services: allPagesYaml {
        nodes {
          title
          serviceArea
          fields {
            slug
          }
        }
      }
    }
  `)
  const currentSlug = useLocation().pathname
  const sortedNodes = sortBy(data.serviceAreas.nodes, [
    area =>
      data.services.nodes.filter(
        (service: Service) => service.serviceArea == area.parent.name
      ).length,
    area => deburr(area.title),
  ])

  return (
    <Stack>
      <Heading as="span" size="md" pb={2}>
        <FontAwesomeIcon icon={faTools} />
        &nbsp;Leistungen
      </Heading>
      {sortedNodes.map((area: ServiceArea) => (
        <SidebarArea
          key={area.parent.name}
          currentSlug={currentSlug}
          area={area}
          onClick={onClick}
          services={sortBy(
            data.services.nodes.filter(
              (service: Service) => service.serviceArea == area.parent.name
            ),
            service => deburr(service.title)
          )}
        />
      ))}
    </Stack>
  )
}

export const DesktopSidebar: React.FC = () => (
  <Box gridArea="sidebar" display={{ base: "none", md: "block" }}>
    <SidebarContent />
  </Box>
)

export const MobileSidebar: React.FC<MobileProps> = ({ isOpen, onClose }) => (
  <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
    <DrawerOverlay>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Navigation</DrawerHeader>
        <DrawerBody>
          <Stack spacing={5}>
            <Stack>{mainmenu(onClose)}</Stack>
            <Divider />
            <SidebarContent onClick={onClose} />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </DrawerOverlay>
  </Drawer>
)
