/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import {
  Box, Container,
  Grid
} from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Footer from "./footer"
import Header from "./header"
import { DesktopSidebar, MobileSidebar } from "./sidebar"


interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
  const isHome = useLocation().pathname === "/"

  return (
    <Grid templateAreas="'header' 'main' 'footer'" gap={5}>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        onShowSidebar={toggleSidebar}
      />
      <Container maxW="container.xl" gridArea="main">
        {isHome ? (
          <>
            <MobileSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
            <Box as="main" gridArea="content">
              {children}
            </Box>
          </>
        ) : (
          <Grid
            templateAreas="'sidebar content'"
            templateColumns="1fr 10fr"
            gap={10}
            display={{ base: "block", md: "grid" }}
          >
            <DesktopSidebar />
            <MobileSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
            <Box as="main" gridArea="content">
              {children}
            </Box>
          </Grid>
        )}
      </Container>
      <Footer />
    </Grid>
  )
}

export default Layout
