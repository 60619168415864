import React from "react"
import Layout from "./components/layout"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"

library.add(fas)

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}
